// extracted by mini-css-extract-plugin
export var backgroundImage = "BareMetalIndex__backgroundImage__YFuV7";
export var column = "BareMetalIndex__column__UpZ08";
export var detailsContent = "BareMetalIndex__detailsContent__qd0lu";
export var detailsSection = "BareMetalIndex__detailsSection__skTlM";
export var detailsSidebar = "BareMetalIndex__detailsSidebar__h7rq4";
export var faqSection = "BareMetalIndex__faqSection__CSrmD";
export var getStartedSection = "BareMetalIndex__getStartedSection__SQsm5";
export var highlightsSection = "BareMetalIndex__highlightsSection__TQ9V9";
export var jumbotronSection = "BareMetalIndex__jumbotronSection__iT04U";
export var providerIconsSection = "BareMetalIndex__providerIconsSection__lz9Hb";
export var requestEstimateBareMetalSection = "BareMetalIndex__requestEstimateBareMetalSection__mbLN2";
export var row = "BareMetalIndex__row__Nv8nq";
export var wideContainer = "BareMetalIndex__wideContainer__Jzo_h";
export var wrapper = "BareMetalIndex__wrapper__bvy2I";